<template>
  <div :class="bem('energy-value', { size, reverce })">
    <img class="energy-value__icon" src="@/assets/icons/coins-yellow.png" />
    <div class="energy-value__text">
      {{ energyString }}
    </div>
  </div>
</template>

<script>
import { formatNumber } from '@/lib/utils'

export default {
  name: 'EnergyValue',
  props: {
    value: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['medium', 'big', 'huge'].includes(value)
      },
    },
    reverce: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    energyString() {
      return formatNumber(this.value)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.energy-value {
  display: flex;
  align-items: center;
  gap: 4rem;

  &_size {
    &_medium {
      font-size: 16rem;

      .energy-value__icon {
        width: 21rem;
      }
    }

    &_big {
      font-size: 24rem;

      .energy-value__icon {
        width: 32rem;
      }
    }

    &_huge {
      font-size: 42rem;

      .energy-value__icon {
        width: 40rem;
      }
    }
  }

  &_reverce {
    flex-direction: row-reverse;
  }

  &__icon {
    margin-right: 5rem;
  }

  &__text {
    font-weight: bold;
    line-height: 1.5;
  }
}
</style>
