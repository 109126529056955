<template>
  <div class="page-main">
    <BoxGame
      v-if="$store.state.boxGameOpened || box.crashed"
      class="page-main__box-game"
    />
    <!-- // TODO скрывать если жизней бокса 0 -->
    <GameContent v-else class="page-main__game-content" />
  </div>
</template>

<script>
import GameContent from '@/components/GameContent.vue'
import BoxGame from '@/components/BoxGame/BoxGame.vue'
import { mapState } from 'vuex'

export default {
  name: 'HomeView',
  components: {
    BoxGame,
    GameContent,
  },
  data: () => ({
    invitedSquad: {},
    joinLoading: false,
    squadInfoLoading: false,
    hideLoading: false,
  }),
  computed: {
    ...mapState('user', ['user', 'box']),
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.page-main {
  display: flex;
  flex-direction: column;

  &__logo {
    position: relative;
    z-index: 10;
    display: block;
    width: 400rem;
    margin: 10rem auto 10rem;
  }

  &__launch-progress {
    position: relative;
    z-index: 10;
    margin: 0 15rem;
  }

  &__game-content {
    position: relative;
    z-index: 10;
  }

  &__box-game {
    position: fixed !important;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
