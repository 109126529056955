import tap from '@/assets/images/text-tap-ru.png'

export default {
  tools: {
    squadLevels: {
      bronze: 'Бронза',
      silver: 'Серебро',
      gold: 'Золото',
      diamond: 'Бриллиант',
    },
    rotateMessage: 'Переверните устройство, пожалуйста',
    disconnectedMessage: 'Вы запустили игру на втором устройстве',
    playOnMobile: 'Запустите игру на\n мобильном устройстве',
  },
  countdown: {
    d: 'Д',
  },
  popupPreviusBalance: {
    head: 'Ваш прошлый баланс:',
    ok: 'ОК',
  },
  pageMain: {},
  gameContent: {
    tabFarm: 'Фарм',
    tabTop: 'Рейтинг',
    tabMySquad: 'Мой сквад',
    yourSquadHead: 'Твой сквад',
  },
  tasksList: {
    joinChannel: {
      title: 'Подпишись на&nbsp;канал Hyper',
      buttonText: 'Подписаться',
    },
    invite: {
      title: 'Пригласи друзей',
      buttonText: 'Пригласить',
      terms: 'условия',
    },
    joinSquad: {
      title:
        'Вступи в сквад и&nbsp;получай <span style="white-space: nowrap"><b>{reward}</b> {icon}</span> каждые <b>8</b> часов',
      buttonText: 'Сквады',
    },
    waitSquadEnergy: {
      title: 'Восстановление энергии +{ value } { icon }',
    },
    getSquadEnergy: {
      title: 'Энергия восстановлена',
      buttonText: 'Получить',
    },
  },
  invitedSquad: {
    title: 'Вы получили приглашение от этой команды',
  },
  squadsList: {
    yourSquadHead: 'Твой сквад',
    listHead: 'Топ сквады',
    competition: 'Соревнование',
  },
  squadsEmpty: {
    text: 'Ещё никто не создал сквады. Будь первым, кто создаст его',
    buttonText: 'Создать сквад',
  },
  squadItem: {
    buttonJoin: 'Вступить',
    buttonLeave: 'Покинуть',
    commission: 'комиссия',
    place: 'место',
  },
  popupSquad: {
    joinSquad: 'Присоединиться к скваду',
  },
  popupBuyAd: {
    head: 'Купить рекламу',
    text: '1000 показов 300k {icon}',
    counterHead: 'Показы',
    rulesTitle: 'Правила',
    rulesList: {
      req: 'Реклама незаконной деятельности, политическая реклама и ссылки, содержащие оскорбления, будут удалены без возврата Hypercoin',
    },
    inputLabel: 'Ссылка на <span>свой(!)</span> Telegram-канал/чат',
    buttonText: 'Купить за {value}К',
    errors: {
      invalidUrl: 'Пожалуйста, проверь URL-адрес',
      invalidDomain: 'Ссылка должна начинаться с t.me',
      noCoins: 'Недостаточно Hypercoins для подачи заявки',
      later: 'Невозможно подать заявку, попробуйте еще раз позже',
    },
  },
  popupSquadInvite: {
    head: 'Приглашение вступить в сквад',
    join: 'Вступить',
    decline: 'Отклонить',
  },
  popupJoinSquad: {
    head: 'Ты вступил в сквад',
    ok: 'Ок',
  },
  popupLeaveSquad: {
    head: 'Хочешь покинуть сквад?',
    in: 'через',
    text: 'Выход из сквада сбросит таймер восстановления энергии',
    stay: 'Остаться',
    leave: 'Покинуть',
  },
  popupChangeSquad: {
    head: 'Предложение\nсменить сквад',
    squadFromHead: 'Ваш текущий сквад',
    squadToHead: 'Новый сквад',
    buttonChange: 'Сменить сквад',
    buttonDecline: 'Отклонить',
  },
  boxPreview: {
    buttonText: 'Начать игру',
    rulesList: {
      players: '{icon} <span>Все игроки наносят урон одному лутбоксу</span>',
      damage: '{icon} <span>Больше игроков в рейде — выше урон</span>',
      coins:
        '{icon} <span>Hypercoins распределятся пропорционально нанесенному урону</span>',
      cashback:
        '{icon} <span>Кэшбэк <b>{friends}%</b> от приглашенных друзей и <b>{friendsFriends}%</b> кэшбэк от друзей ваших друзей</span>',
      ton: '{icon} <span><b>{value} TON</b> будут распределены среди наиболее активных 😇 <b>Believers</b></span>',
    },
  },
  boxGameScene: {
    tap: tap,
    buttonInviteText: 'Пригласить друзей',
    buttonCollectReward: 'Получить',
    rewardTitle: 'Hypercoins будут начислены в течение нескольких дней.',
    rewardDescription: 'Вы получите уведомление от {bot} .',
    disclaimer1: '{value}% HYPERCOINS CASHBACK от фарма друзей',
    disclaimer2: '{value}% HYPERCOINS cASHBACK за друзей ваших друзей',
  },
  popupInviteTerms: {
    head: 'Твоя награда за приглашение друга',
    text: 'Ты получишь награду, когда друг нафармит {userThreshold} монет',
    ok: 'Ок',
  },
  popupGetReward: {
    // TODO поменять текст модалки
    head: 'Получи свою награду',
    rewards: {
      friends: ({ plural, named }: PluralNamed) =>
        plural([
          `Твоя награда за ${named('value')} приглашенного друга`,
          `Твоя награда за ${named('value')} приглашенных друзей`,
          `Твоя награда за ${named('value')} приглашенных друзей`,
        ]),
      telegramSubscribed: 'Ты успешно подписался\nна телеграм канал',
    },
    ok: 'Получить',
  },
  noSquadInfo: {
    buttonCreateSquad: 'Создать свой сквад',
    or: 'или',
    joinSquadText:
      'Вступи в сквад и получай <span style="white-space: nowrap"><b>{reward}</b> {icon}</span> каждые <b>8</b> часов',
  },
  mySquadManager: {
    head: 'Статистика',
    inviteButton: ' Пригласить в сквад',
  },
  squadStats: {
    income: 'Доход',
    members: 'Члены сквада',
  },
  squadFeeRange: {
    title: 'Комиссия сквада',
    description: 'Чем ниже комиссия сквада,\nтем выше рейтинг сквада',
  },
  sort: {
    today: 'Сегодня',
    week: 'Неделя',
  },
  errorPage: {
    title: 'Произошла ошибка',
    text: 'К сожалению, произошла ошибка. Пожалуйста, попробуйте перезагрузить приложение или зайдите позже.',
    buttonReloadText: 'Перезагрузить',
    buttonSupprotText: 'Написать в поддержку',
  },
  adBanner: {
    info: 'Реклама',
  },
}
