<template>
  <div class="box-game-scene" @pointerdown="hit" @touchstart="onTouchStart">
    <MainHeader
      @pointerdown.stop=""
      @touchstart.stop=""
      class="box-game-scene__header"
      v-if="user"
    />
    <ad-banner v-if="showAd" @close="showAd = false" />
    <div v-if="loading && isExplodeVisible" class="box-game-scene__loading">
      <SpinnerBlock class="box-game-scene__spinner-block" />
    </div>
    <div
      :class="bem('box-game-scene__mute', { active: isMute })"
      @pointerdown.stop=""
      @touchstart.stop=""
      @click="toggleMute"
    ></div>
    <div class="box-game-scene__content">
      <div
        v-show="isRewardVisible"
        ref="rewardHead"
        class="box-game-scene__reward-head"
        v-html="$t('boxGameScene.rewardTitle')"
      ></div>
      <BoxGameSceneScale
        class="box-game-scene__box-game-scene-scale"
        :style="{ opacity: isExplodeVisible ? 0 : 1 }"
      />
      <div
        class="box-game-scene__boost-text"
        :style="{ opacity: isExplodeVisible ? 0 : 1 }"
      >
        BOOST: 💪 {{ Math.round(bonusDamagePercentTweend) }}%
      </div>
      <div
        class="box-game-scene__raid-text"
        :style="{ opacity: isExplodeVisible ? 0 : 1 }"
      >
        ONLINE: <img src="@/assets/icons/raid.svg" />
        {{ playersOnlineTweend.toFixed(0) }}
      </div>
      <div
        class="box-game-scene____top"
        :style="{ opacity: isExplodeVisible ? 0 : 1 }"
      >
        <div class="box-game-scene__health-wrapper">
          <div
            :class="bem('box-game-scene__health-angel', { type: 'top-left' })"
          ></div>
          <div
            :class="bem('box-game-scene__health-angel', { type: 'top-right' })"
          ></div>
          <div
            :class="
              bem('box-game-scene__health-angel', { type: 'bottom-left' })
            "
          ></div>
          <div
            :class="
              bem('box-game-scene__health-angel', { type: 'bottom-right' })
            "
          ></div>
          <ProgressBar
            class="box-game-scene__health"
            ref="health"
            :max-value="maxHealth"
            :value="health"
            color="green-intense"
            no-icon
            max-value-visible
            size="normal"
          />
        </div>
      </div>

      <div ref="box" :class="bem('box-game-scene__box', { damage })">
        <div
          v-show="isExplodeVisible"
          ref="boxExplode"
          :class="bem('box-game-scene__box-explode', { type: explodeType })"
        />
      </div>

      <div
        v-if="isGameTipVisible"
        ref="gameTip"
        class="box-game-scene__bottom-container"
      >
        <div class="box-game-scene__tap">
          <img
            class="box-game-scene__tap-image"
            :src="$t('boxGameScene.tap')"
          />
        </div>
        <img
          ref="tapIcon"
          class="box-game-scene__tap-icon"
          src="@/assets/images/hand-lined.png"
        />
      </div>
      <div
        v-else
        class="box-game-scene__bottom-container"
        :style="{ opacity: isExplodeVisible ? 0 : 1 }"
      >
        <div class="box-game-scene__stat-text-wrapper">
          <div :class="bem('box-game-scene__stat-text', { size: 'big' })">
            💥 {{ formatNumber(Number(totalDamageTweend).toFixed(2)) }}
          </div>
          <div class="box-game-scene__stat-text">
            <div><img src="@/assets/icons/people.svg" />:</div>
            <div>💥 {{ formatNumber(Number(friendsDamageTweend)) }}</div>
          </div>
        </div>
        <ButtonAction
          class="box-game-scene__button-invite-friend"
          size="big"
          color="yellow"
          @pointerdown.stop=""
          @touchstart.stop=""
          @click="inviteFriend"
        >
          {{ $t('boxGameScene.buttonInviteText') }}
        </ButtonAction>
        <div class="box-game-scene__disclaimer-text">
          {{ $t('boxGameScene.disclaimer1', { value: 25 }) }}
        </div>
        <div class="box-game-scene__disclaimer-text">
          {{ $t('boxGameScene.disclaimer2', { value: 5 }) }}
        </div>
      </div>
      <div
        v-show="isRewardVisible"
        ref="rewardContainer"
        class="box-game-scene__reward-container"
      >
        <div class="box-game-scene__reward-value">
          {{ $t('boxGameScene.rewardDescription', { bot: '@hypercoin_bot' }) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ProgressBar from '@/components/ProgressBar.vue'
import gsap from 'gsap'
import { formatNumber } from '@/lib/utils'
import eventBus from '@/lib/eventBus'
import SpinnerBlock from '../SpinnerBlock.vue'
import ButtonAction from '@/components/ButtonAction.vue'
import BoxGameSceneScale from './BoxGameSceneScale.vue'
import AdBanner from '../AdBanner.vue'
import WebSocketService from '@/services/WebSocketService'
import MainHeader from '@/components/MainHeader.vue'

const MAX_DAMAGE = 2

export default {
  name: 'BoxGameScene',
  components: {
    ProgressBar,
    SpinnerBlock,
    ButtonAction,
    BoxGameSceneScale,
    AdBanner,
    MainHeader,
  },
  emits: ['before-crush', 'reload', 'close'],
  data: () => ({
    isHitTweenPlaying: false,
    isSpawnPlaying: false,
    isGameTipVisible: true,
    crushedValue: 0,
    isExplodeVisible: false,
    isAppearVisible: false,
    isRewardVisible: false,
    collectLoading: false,
    explodeType: 'coins',
    direction: 'down',
    lockHit: true,
    energyAfterGame: 0,
    // Для отладки
    scaleLines: [0.17, 0.5, 0.77],
    loading: false,
    canSkipReward: false,
    showAd: false,
    hitsBeforeAdShow: Number(process.env.VUE_APP_HITS_BEFORE_AD_SHOW) + 1,
    health: Infinity,
    isCushed: false,
    totalDamageTweend: 0,
    friendsDamageTweend: 0,
    bonusDamagePercentTweend: 0,
    playersOnlineTweend: 0,
  }),
  async mounted() {
    this.animateHand()
    this.health = this.getHealth
    this.lockHit = false
    this._damageRateInterval = setInterval(() => {
      this.health = this.health - this.box.damageRate
    }, 1000)
  },
  watch: {
    totalDamage: {
      handler(n) {
        gsap.to(this.$data, {
          duration: 1,
          totalDamageTweend: Number(n) || 0,
        })
      },
      immediate: true,
    },
    friendsDamage: {
      handler(n) {
        gsap.to(this.$data, {
          duration: 1,
          friendsDamageTweend: Number(n) || 0,
        })
      },
      immediate: true,
    },
    bonusDamagePercent: {
      handler(n) {
        gsap.to(this.$data, {
          duration: 0.5,
          bonusDamagePercentTweend: Number(n) || 0,
        })
      },
      immediate: true,
    },
    playersOnline: {
      handler(n) {
        gsap.to(this.$data, {
          duration: 0.5,
          playersOnlineTweend: Number(n) || 0,
        })
      },
      immediate: true,
    },
    health: {
      async handler(value) {
        if (value <= 0 || this.box.crashed) {
          this.isRewardVisible = true
          this.isGameTipVisible = false
          await this.$nextTick()
          this.crush()
        }
      },
      immediate: true,
    },
    hits() {
      this.updatуStats()
    },
  },
  unmounted() {
    if (this._handTween) {
      this._handTween.kill()
    }
    clearInterval(this._damageRateInterval)
  },
  computed: {
    ...mapState('user', ['user', 'box', 'hits']),
    ...mapGetters('constants', ['constants']),
    ...mapState('device', ['isMute']),
    playersOnline() {
      return this.box.playersOnline
    },
    totalDamage() {
      return this.hits.totalDamage
    },
    friendsDamage() {
      return this.hits.friendsDamage
    },
    bonusDamagePercent() {
      return this.box.bonusDamagePercent
    },
    maxHealth() {
      // Подклюсить к апи
      return this.box.health
    },
    damage() {
      const ratio = 1 - this.health / this.maxHealth

      return Math.round(ratio * MAX_DAMAGE)
    },
    getHealth() {
      return this.box.health - this.box.damage
    },
    canHit() {
      return (
        this.health > 0 &&
        !this.isHitTweenPlaying &&
        !this.lockHit &&
        !this.isAppearVisible &&
        !this.showAd &&
        !this.isCushed
      )
    },
  },
  methods: {
    formatNumber,
    toggleMute() {
      eventBus.emit('toggle-mute')
    },
    updatуStats() {
      this.health = Math.min(this.getHealth, this.health)
    },
    generateRandomNumber(min, max) {
      return Math.random() * (max - min) + min
    },
    showAdd() {
      this.hitsBeforeAdShow = this.hitsBeforeAdShow - 1
      if (this.hitsBeforeAdShow < 1) {
        this.showAd = true
        this.hitsBeforeAdShow =
          Number(process.env.VUE_APP_HITS_BEFORE_AD_SHOW) + 2
      }
    },
    inviteFriend() {
      this.$store.dispatch('inviteFriend')
    },
    async appear() {
      this.isAppearVisible = true

      await this.$nextTick()

      this._appearTl = gsap.timeline()
      const duration = 0.3

      eventBus.emit('play-sound', 'spawn')

      this._appearTl.to(this.$refs.boxAppear, {
        backgroundPositionX: '100%',
        duration: 0.7,
        ease: 'steps(24)',
        onComplete: () => {
          this.isAppearVisible = false
        },
      })
      this._appearTl.from(this.$refs.scaleContainer, {
        x: '80rem',
        duration: duration,
      })
      this._appearTl.from(
        this.$refs.health.$el,
        {
          delay: 0.2,
          y: '-100rem',
          opacity: 0,
          duration: duration,
        },
        '<'
      )

      if (this.$refs.gameTip) {
        this._appearTl.from(
          this.$refs.gameTip,
          {
            y: '100rem',
            opacity: 0,
            duration: duration,
          },
          '<'
        )
      }

      this._appearTl.then()
    },
    animateHand() {
      this._handTween = gsap.to(this.$refs.tapIcon, {
        scale: 0.7,
        ease: 'linear',
        repeat: -1,
        duration: 0.3,
        yoyo: true,
      })
    },
    getHitPower() {
      return 1
    },
    async hit(e) {
      if (this.isAppearVisible && this._appearTl) {
        this._appearTl.timeScale(0.5)
        this.isAppearVisible = false
        gsap.to(this.$refs.health.$el, {
          y: '0',
          opacity: 1,
        })
        eventBus.emit('play-sound')
      }

      this.showAdd()

      if (this.canSkipReward && this._explodeTl) {
        this._explodeTl.clear()

        gsap.to(this.$refs.box, {
          backgroundImage: 'initial',
        })

        gsap.to(this.$refs.boxExplode, {
          opacity: 0,
          duration: 0.1,
          onComplete: () => {
            this.$emit('reload')
          },
        })
      }

      if (!this.canHit) {
        return
      }

      this.isHitTweenPlaying = true

      const hitPower = this.getHitPower()

      this.health = this.health - this.hits.lastHitDamage || 1

      WebSocketService.sendMessage('hit', {
        coords: {
          x: e.clientX,
          y: e.clientY,
        },
      })

      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'hitLootbox',
      })

      eventBus.emit('play-sound', `hit${hitPower}`)

      this.safeVibrate()

      const isGameTipVisible = this.isGameTipVisible

      if (isGameTipVisible) {
        this._handTween.kill()

        gsap.to(this.$refs.gameTip, {
          duration: 0.5,
          opacity: 0,
          ease: 'power1.inOut',
          onComplete: () => {
            this.isGameTipVisible = false
          },
        })
      }

      if (!this._boxShake) {
        this._boxShake = gsap.timeline().to(this.$refs.box, {
          duration: 0.05,
          scale: 0.85,
          ease: 'power1.inOut',
          yoyo: true,
          repeat: 1,
          onComplete: () => {
            this._boxShake = null
          },
        })
      }

      this.isHitTweenPlaying = false
    },
    getGameResults() {
      this.crush()
    },
    crush() {
      if (this.isCushed) return
      this.isCushed = true
      this.$emit('before-crush')

      eventBus.emit('play-sound', 'crush')

      gsap.to(this.$refs.scaleContainer, {
        opacity: 0,
        duration: 0.5,
      })

      // TODO TypeError Cannot read properties of null (reading '$el')
      // GSAP target null not found.
      if (this.$refs.health.$el) {
        gsap.to(this.$refs.health.$el, {
          opacity: 0,
          duration: 0.5,
        })
      }

      this._explodeTl = gsap.timeline()

      this.isExplodeVisible = true

      const stepsMap = {
        coins: 23,
      }
      setTimeout(() => {
        this.isRewardVisible = true
      }, 200)

      this._explodeTl.to(this.$refs.boxExplode, {
        backgroundPositionX: '100%',
        duration: 1,
        ease: `steps(${stepsMap[this.explodeType]})`,
        onComplete: () => {
          this._explodeTl.to(this.$refs.box, {
            backgroundImage: 'initial',
          })
        },
      })
      this._explodeTl.to(this.$refs.box, {
        scale: 0.4,
        duration: 0.2,
      })
      this._explodeTl.fromTo(
        this.$refs.rewardHead,
        {
          opacity: 0,
          y: '-40rem',
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.3,
          ease: 'power2.out',
        }
      )
      this._explodeTl.fromTo(
        this.$refs.rewardContainer,
        {
          opacity: 0,
          y: '60rem',
        },
        {
          opacity: 1,
          y: '20rem',
          duration: 0.3,
          ease: 'power2.out',
        },
        '-=0.3'
      )
    },
    onTouchStart(e) {
      if (this.canHit) {
        e.preventDefault()
      }
    },
    safeVibrate() {
      if (this.isMute) return

      if (navigator.vibrate) {
        try {
          navigator.vibrate(200)
        } catch (e) {
          console.error(e)
        }
      } else {
        try {
          window.Telegram.WebApp.HapticFeedback.impactOccurred('medium')
        } catch (e) {
          console.error(e)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.box-game-scene {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 30;
  }

  &__mute {
    width: 30rem;
    height: 30rem;
    position: absolute;
    top: 20rem;
    left: 20rem;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(@/assets/icons/sound-on.svg);

    &_active {
      background-image: url(@/assets/icons/sound-off.svg);
    }
  }

  &__boost-text {
    font-size: 16rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 6rem;
  }

  &__raid-text {
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 16rem;
    font-weight: 600;
    margin-bottom: 42rem;

    img {
      height: 1.2em;
      margin-left: 0.4em;
      margin-right: 0.4em;
    }
  }

  &__health-wrapper {
    position: relative;
  }

  &__health-angel {
    height: 8rem;
    width: 8rem;
    position: absolute;

    &_type {
      &_top-left {
        border-top: 2rem solid $color-pink;
        border-left: 2rem solid $color-pink;
        top: -6rem;
        left: -6rem;
      }
      &_top-right {
        border-top: 2rem solid $color-pink;
        border-right: 2rem solid $color-pink;
        top: -6rem;
        right: -6rem;
      }
      &_bottom-right {
        border-bottom: 2rem solid $color-pink;
        border-right: 2rem solid $color-pink;
        bottom: -6rem;
        right: -6rem;
      }
      &_bottom-left {
        border-bottom: 2rem solid $color-pink;
        border-left: 2rem solid $color-pink;
        bottom: -6rem;
        left: -6rem;
      }
    }
  }

  &__content {
    width: 100%;
    padding-top: 20rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__reward-head {
    position: absolute;
    z-index: 20;
    top: 140rem;
    left: 50%;
    transform: translateX(-50%);
    width: 347rem;
    opacity: 0;
    text-align: center;
    font-size: 14rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__health {
    width: 200rem;
  }

  &__box-game-scene-scale {
    position: absolute;
    left: 0;
    top: 20rem;
  }

  &__stat-text-wrapper {
    margin-bottom: 60rem;
    font-size: 16rem;
    color: white;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;

    @media screen and (max-height: 35rem) {
      margin-bottom: 20rem;
    }
  }

  &__stat-text {
    max-width: 250rem;
    margin-top: 6rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4.5rem;

    &_size {
      &_big {
        font-size: 22rem;
      }
    }

    img {
      height: 1.2em;
      margin-right: 0.4em;
      margin-top: -0.2rem;
      margin-bottom: -2.2rem;
    }

    display: flex;
    align-items: center;
  }

  &__disclaimer-text {
    font-size: 10rem;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__health-wrapper {
    background: #0d4a4e;
    padding: 1.5rem;
    border: 1.5rem solid #bacfec;
    margin-bottom: 20rem;
  }

  &__image {
    width: 165rem;
  }

  &__hit-power-container {
    position: absolute;
    z-index: 20;
    top: -70%;
    left: 50%;
    transform: translateX(-50%);
    height: 200rem;
  }

  &__hit-power {
    height: 100%;
    opacity: 0;
  }

  &__box {
    position: relative;
    z-index: 10;
    width: 166rem;
    height: 167rem;
    background: center no-repeat;
    background-size: contain;
    pointer-events: none;

    &_damage {
      &_0 {
        background-image: url(@/assets/images/box.png);
      }

      &_1 {
        background-image: url(@/assets/images/box-1.png);
      }

      &_2 {
        background-image: url(@/assets/images/box-2.png);
      }
    }
  }

  &__box-appear {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 510rem;
    height: 510rem;
    // background: left no-repeat url(@/assets/images/box-appear-sprite.png);
    background-size: auto 100%;
    pointer-events: none;
  }

  &__box-explode {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 510rem;
    height: 510rem;
    background-size: auto 100%;
    background-position: left;
    background-repeat: no-repeat;
    pointer-events: none;

    &_type {
      &_coins {
        background-image: url(@/assets/images/box-explode-coin-sprite.png);
      }
    }
  }

  &__bottom-container {
    // position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 170rem;
  }

  &__tap {
    display: flex;
    align-items: center;
  }

  &__tap-image {
    width: 130rem;
  }

  &__tap-icon {
    width: 106rem;
  }

  &__no-energy-icon {
    width: 120rem;
  }

  &__no-energy-text {
    height: 77rem;
    margin-top: -40rem;
  }

  &__button-invite-friend {
    width: 280rem;
    margin-bottom: 14rem;
  }

  &__reward-container {
    position: absolute;
    z-index: 20;
    top: 57%;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
  }

  &__reward-value {
    margin-bottom: 30rem;
    font-size: 14rem;
    text-align: center;
    max-width: 80%;
  }

  &__button-collect-reward {
    width: 300rem;
  }

  &__scale-container {
    position: absolute;
    top: 0;
    right: 20rem;
    height: 100%;
    display: flex;
    align-items: center;
    transition: 0.15s ease-in opacity;

    &_lock {
      opacity: 0.6;
    }
  }

  &__scale {
    position: relative;
    width: 36rem;
  }

  &__scale-image {
    width: 100%;
  }

  &__scale-track {
    position: absolute;
    top: 9%;
    right: 100%;
    width: 0;
    height: 82%;
    // width: 1px;
    // background: red;
  }

  &__scale-line {
    position: absolute;
    top: 0;
    right: 0;
    height: 1px;
    width: 50rem;
    background: red;
    font-size: 12rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: red;
  }

  &__scale-slider {
    position: absolute;
    top: 0;
    right: 0;
    border-style: solid;
    border-width: 10rem 0 10rem 12rem;
    border-color: transparent transparent transparent $color-pink;
    transform: translateY(-50%);
  }

  &__loading {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  &__spinner-block {
    font-size: 24rem;
  }
}
</style>
