<template>
  <div class="box-preview">
    <img
      class="box-preview__box-top"
      src="@/assets/images/box-top.jpg"
      alt="box-top"
    />
    <a
      @click="clickAd()"
      target="_blank"
      :href="sponsorLink"
      class="box-preview__image-wrapper"
    >
      <img
        class="box-preview__image"
        src="@/assets/images/banner.jpg"
        alt="sponsor"
      />
      <div target="_blank" class="box-preview__sponsor-button">
        {{ sponsorName }}
      </div>
    </a>
    <div class="box-preview__rules-list">
      <div
        class="box-preview__rules-item"
        v-html="$t('boxPreview.rulesList.players', { icon: sparkIconTemplate })"
      ></div>
      <div
        class="box-preview__rules-item"
        v-html="$t('boxPreview.rulesList.damage', { icon: damageIconTemplate })"
      ></div>
      <div
        class="box-preview__rules-item"
        v-html="$t('boxPreview.rulesList.coins', { icon: coinsIconTemplate })"
      ></div>
      <div
        class="box-preview__rules-item"
        v-html="
          $t('boxPreview.rulesList.cashback', {
            icon: peopleIconTemplate,
            friends: 25,
            friendsFriends: 5,
          })
        "
      ></div>
    </div>
    <div class="box-preview__footer">
      <img
        class="box-preview__box-bottom"
        src="@/assets/images/box-bottom.jpg"
        alt="box-bottom"
      />
      <ButtonAction
        class="box-preview__button"
        color="green"
        size="big"
        @click="openBoxGame"
        :loading="loading"
      >
        {{ $t('boxPreview.buttonText') }}
      </ButtonAction>
      <div
        :class="bem('box-preview__beliver', { hide: !user?.isEarlyAdopter })"
      >
        😇 believer
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ButtonAction from '@/components/ButtonAction.vue'
import {
  damageIconTemplate,
  coinsIconTemplate,
  peopleIconTemplate,
  raidIconTemplate,
  tonBlueIconTemplate,
  sparkIconTemplate,
} from '@/lib/iconTemplates'

export default {
  name: 'BoxPreview',
  components: { ButtonAction },
  data: () => ({
    loading: false,
    damageIconTemplate,
    coinsIconTemplate,
    peopleIconTemplate,
    raidIconTemplate,
    tonBlueIconTemplate,
    sparkIconTemplate,
  }),
  methods: {
    async openBoxGame() {
      try {
        // this.loading = true
        this.$store.dispatch('eventManager/trackEvent', {
          eventType: 'StartBreakingLootboxes',
        })
        // await this.$store.dispatch('user/fetchUser')
        await this.$store.dispatch('watchAdd')
        this.$store.commit('openBoxGame')
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    clickAd() {
      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'clickMainAd',
        eventProperties: { link: this.sponsorLink },
      })
    },
  },
  computed: {
    ...mapState('user', ['user']),
    sponsorName() {
      return process.env.VUE_APP_MAIN_SPONSOR_NAME
    },
    sponsorLink() {
      return process.env.VUE_APP_MAIN_SPONSOR_LINK
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.box-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100vh;

  &__health {
    width: 160rem;
    margin-bottom: 8rem;
  }

  $mainBoxSteps: 24;
  $mainBoxSize: 155rem;

  &__image-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 20rem;
    display: block;
  }

  &__sponsor-button {
    font-size: 14rem;
    position: absolute;
    top: 67%;
    left: 65%;
    padding: 5rem 13rem;
    border-radius: 40rem;
    background: #2381c9;
    font-weight: bold;
  }

  &__image {
    width: 100%;
    margin-top: 15rem;
  }

  &__box-top {
    width: 100%;
    margin-right: auto;
    margin-bottom: -10rem;
    position: relative;
    z-index: 1;

    @media screen and (max-height: 35rem) {
      width: 50%;
      margin-bottom: 10rem;
    }

    @media screen and (max-height: 30rem) {
      display: none;
    }
  }

  &__box-bottom {
    width: 90%;
    margin-left: auto;
    margin-top: -30rem;

    @media screen and (max-height: 35rem) {
      width: 50%;
      margin-bottom: 10rem;
    }

    @media screen and (max-height: 30rem) {
      display: none;
    }
  }

  &__footer {
    width: 100%;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80rem;
  }
  &__beliver {
    font-size: 14rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;

    &_hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__rules-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8rem;
    margin-bottom: 16rem;
    padding: 0 16rem;
  }

  &__rules-item {
    font-size: 16rem;
    position: relative;
    display: flex;
    align-items: center;
    gap: 8rem;

    div {
      flex-shrink: 0;
    }
  }

  @keyframes mainBoxAnimate {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: calc(-#{$mainBoxSize * $mainBoxSteps}) 0;
    }
  }

  @keyframes scaleAnimate {
    0%,
    100% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1.1);
    }
  }

  &__button {
    width: 340rem;
    &-icon {
      height: 1em;
      margin-right: 0.2em;
      margin-bottom: -0.13em;
    }
    margin-bottom: 10rem;
  }
}
</style>
